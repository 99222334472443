import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Divider, Drawer, IconButton, List, ListSubheader, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { APP_TITLE, DRAWER_WIDTH } from 'config/constants'
import { archiveLinks, generalLinks } from 'config/routes/sidebar'
import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { useCallback } from 'react'
import { spacing } from 'utils/styles'
import SideBarListItem from './SideBarListItem'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1, 0, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}))

const FixedWidthDrawer = styled(Drawer)`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    box-sizing: border-box;
  }
`

const CompactListSubheader = styled(ListSubheader)`
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 2;
  font-weight: bold;
  margin-top: ${spacing(2)};
`

interface SideBarProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const SideBar = (props: SideBarProps) => {
  const { setOpen, open } = props
  const isSmallScreen = useIsSmallScreen()

  // When a link is clicked on a small size device, the drawer will be
  // a temporary one so we should close it when they navigate.
  const handleLinkClick = useCallback(() => {
    if (isSmallScreen) setOpen(false)
  }, [isSmallScreen, setOpen])

  const drawerContent = (
    <Box display="flex" flexDirection="column" height="100%">
      <DrawerHeader>
        <Stack justifyContent="flex-end" alignSelf="flex-end" pb={0.5}>
          <Typography
            variant="h5"
            fontFamily="Monoton, cursive"
            lineHeight={1}
            fontWeight="400"
            textTransform="uppercase"
          >
            {APP_TITLE}
          </Typography>

          <Typography sx={{ fontSize: 10 }} variant="body2" color="text.secondary" textAlign="center">
            v{APP_VERSION}
          </Typography>
        </Stack>

        <IconButton onClick={() => props.setOpen(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List subheader={<CompactListSubheader>General</CompactListSubheader>}>
        {generalLinks.map((link) => (
          <SideBarListItem key={link.path} link={link} onClick={handleLinkClick} />
        ))}
      </List>
      <List subheader={<CompactListSubheader>Archive</CompactListSubheader>}>
        {archiveLinks.map((link) => (
          <SideBarListItem key={link.path} link={link} onClick={handleLinkClick} />
        ))}
      </List>
    </Box>
  )

  return isSmallScreen ? (
    <FixedWidthDrawer
      variant="temporary"
      open={open}
      onClose={() => setOpen(false)}
      aria-label="mobile-sidebar"
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {drawerContent}
    </FixedWidthDrawer>
  ) : (
    <FixedWidthDrawer aria-label="desktop-sidebar" variant="persistent" anchor="left" open={open}>
      {drawerContent}
    </FixedWidthDrawer>
  )
}

export default SideBar
