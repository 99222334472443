import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { CSSProperties, isValidElement, memo } from 'react'
import { spacing } from '../utils/styles'

const LoadingSplashWrapper = styled('div')`
  display: flex;
  height: 100%;
  min-height: 250px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SpinnerWrapper = styled('div')`
  height: ${spacing(6)};
  width: ${spacing(6)};
  margin: ${spacing(2)};
`

export interface LoadingSplashProps {
  /** The primary text to display above the spinner */
  title?: string
  /** An informational message to display below the loading spinner */
  message?: string | React.ReactNode
  className?: string
  style?: CSSProperties
  animationElement?: React.ReactElement
}

/**
 * ### Purpose
 *
 * The `LoadingSplash` is a placeholder to display while asynchronous content is being loaded.
 */
function LoadingSplash(props: LoadingSplashProps) {
  const { message, title = 'Loading', style = {}, animationElement } = props
  return (
    <LoadingSplashWrapper style={style}>
      <Typography variant="h2" data-testid="title">
        {title}
      </Typography>
      {animationElement !== undefined ? (
        <>{animationElement}</>
      ) : (
        <SpinnerWrapper>
          <CircularProgress size={50} />
        </SpinnerWrapper>
      )}
      {isValidElement(message) ? (
        message
      ) : (
        <Typography variant="subtitle1" data-testid="message">
          {message}
        </Typography>
      )}
    </LoadingSplashWrapper>
  )
}

export default memo(LoadingSplash)
