import type { Color, PaletteOptions } from '@mui/material'
import { alpha } from '@mui/material/styles'

export type AppTheme = 'light' | 'dark' | 'highContrast'

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Color
    isHighContrast: boolean
    key: AppTheme
  }

  interface PaletteOptions {
    neutral?: Partial<Color>
    isHighContrast: boolean
    key: AppTheme
  }
}

const neutral = {
  '100': '#F5F5F5',
  '200': '#E0E0E0',
  '300': '#BDBDBD',
  '400': '#9E9E9E',
  '500': '#757575',
  '600': '#616161',
  '700': '#424242',
  '800': '#303030',
  '900': '#212121',
} as const

const highContrastNeutral = {
  '100': '#FFFFFF', // White
  '200': '#F5F5F5', // Light gray
  '300': '#D3D3D3', // Medium gray
  '400': '#A9A9A9', // Dark gray
  '500': '#000000', // Black
  '600': '#000000', // Black (same as 500 for consistency)
  '700': '#000000', // Black (same as 500 for consistency)
  '800': '#000000', // Black (same as 500 for consistency)
  '900': '#000000', // Black (same as 500 for consistency)
} as const

// For the light theme we use the color from the primary palette
export const primaryLight = '#FF6F00'
// On a dark theme the primary palette is a bit hard to make out so we use the secondary color instead
export const primaryDark = '#FF6F00'

export const secondary = '#00BCD4'

const success = { main: '#00B140', contrastText: '#FFFFFF' }
const error = { main: '#B3261E', contrastText: '#FFFFFF' }
const info = { main: '#005EB8', contrastText: '#FFFFFF' }
const warning = { main: '#EA7600', contrastText: '#FFFFFF' }

export const lightPalette = {
  mode: 'light',
  key: 'light',
  isHighContrast: false,
  background: { paper: '#FFFFFF', default: '#F9FAFC' },
  action: {
    active: neutral[500],
    focus: 'rgba(0, 0, 0, 0.12)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabledBackground: '#E9EAEC',
    disabled: '#666666',
  },
  primary: { main: primaryLight },
  secondary: { main: secondary },
  text: { primary: '#333333', secondary: '#4D4D4D' },
  success,
  info,
  warning,
  error,
  divider: '#B5B8C0',
  neutral,
} as const satisfies PaletteOptions

export const darkPalette = {
  mode: 'dark',
  key: 'dark',
  isHighContrast: false,
  background: { paper: '#151515', default: '#0F0F0F' },
  action: {
    active: neutral[400],
    focus: 'rgba(255, 255, 255, 0.12)',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: alpha(primaryDark, 0.08),
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabled: 'rgba(255, 255, 255, 0.26)',
  },
  primary: { main: primaryDark },
  secondary: { main: secondary },
  text: { primary: '#FFFFFF', secondary: '#B3B3B3' },
  success,
  error,
  info,
  warning,
  divider: 'rgba(255, 255, 255, 0.12)',
  neutral,
} as const satisfies PaletteOptions

export const highContrastDarkPalette = {
  mode: 'dark',
  key: 'highContrast',
  isHighContrast: true,
  background: { paper: '#000000', default: '#000000' },
  action: {
    active: neutral[400],
    focus: 'rgba(255, 255, 255, 0.2)',
    hover: 'rgba(255, 255, 255, 0.12)',
    selected: alpha(primaryDark, 0.04),
    disabledBackground: 'rgba(255, 255, 255, 0.2)',
    disabled: 'rgba(255, 255, 255, 0.4)',
  },
  primary: { main: primaryDark },
  secondary: { main: secondary },
  text: { primary: '#FFFFFF', secondary: '#FFFFFF' },
  success,
  error,
  info,
  warning,
  divider: 'rgba(255, 255, 255, 1.0)',
  neutral: highContrastNeutral,
} as const satisfies PaletteOptions
