import { styled } from '@mui/material/styles'
import { spacing } from 'utils/styles'
import { DRAWER_WIDTH } from 'config/constants'

function shouldForwardProp(prop: string) {
  return prop !== 'open'
}

const ContentWrapper = styled('main', { shouldForwardProp })<{ open: boolean }>`
  flex-grow: 1;
  overflow: auto;
  position: relative;

  /* Move the content down under the app bar and adjust the height */
  top: ${spacing(8)};
  height: calc(100vh - ${spacing(8)});

  /* On xs size screens the app bar drops down in height */
  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    top: ${spacing(7)};
    height: calc(100vh - ${spacing(7)});
  }

  /* By default the max width is 100% of the screen with no margin on the side */
  margin-left: 0;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    /*
      When on a md or above device, the drawer moves the content over
      so we need to use a negative margin to move it back if the drawer
      is closed and adjust the max-width accordingly.
     */
    margin-left: ${({ open }) => (open ? 0 : -DRAWER_WIDTH)}px;
    max-width: calc(100% - ${({ open }) => (open ? DRAWER_WIDTH : 0)}px);

    /* Transitions for the margin and max-width that open and close */
    transition: ${({ theme, open }) =>
      open
        ? theme.transitions.create(['margin', 'max-width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          })
        : theme.transitions.create(['margin', 'max-width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
  }
`

interface ContentProps {
  children: React.ReactNode
  drawerOpen: boolean
}

export default function Content(props: ContentProps) {
  return <ContentWrapper open={props.drawerOpen}>{props.children}</ContentWrapper>
}
