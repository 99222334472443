import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * This hook will return true if the screen is in either of the `sm` or `xs` breakpoints. Can be used for targeting
 * smaller devices like very small laptops, portrait mode tables and landscape phones. If you want to target
 * portrait mode phones, use `useIsXSScreen` instead.
 */
export function useIsSmallScreen() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('md'))
}
