import { DotLottiePlayer } from '@dotlottie/react-player'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import lottieRocketPath from 'assets/lottie/rocket.lottie?url'
import ActionButton from 'components/ActionButton'
import { useState } from 'react'
import toast from 'react-hot-toast'
import globalLogger from 'utils/logger'
import { useRegisterSW } from 'virtual:pwa-register/react'

const ReloadPrompt = () => {
  const [dismissed, setDismissed] = useState(false)
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(url, registration) {
      if (registration) {
        setInterval(async () => {
          if (registration.installing || !navigator) {
            globalLogger.info('SW', 'SW is installing, skipping update check')
            return
          }

          if ('connection' in navigator && !navigator.onLine) {
            globalLogger.info('SW', 'Skipping update check due to offline status')
            return
          }

          globalLogger.info('SW', 'Checking for SW updates: ', registration)
          const response = await fetch(url, {
            cache: 'no-store',
            headers: { cache: 'no-store', 'cache-control': 'no-cache' },
          })

          if (response.status === 200) {
            globalLogger.info('SW', 'Check returned successfully, triggering update: ', registration)
            await registration.update()
          }
        }, 60 * 1000)
      }

      globalLogger.info('SW', 'SW Registered: ', registration)
    },
    onRegisterError(error) {
      toast.error(`There was an error installing the service worker: ${error}`)
      globalLogger.error('SW', 'SW Registration Error: ', error)
      Sentry.captureException(error)
    },
  })

  const close = () => {
    setDismissed(true)
  }

  return (
    <Dialog open={needRefresh && !dismissed} maxWidth="xs">
      <DialogContent>
        <Typography variant="h5" component="h2" textAlign="center" sx={{ mt: 2 }}>
          Update Available
        </Typography>
        <Box
          sx={{
            overflow: 'visible',
            height: 320,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DotLottiePlayer src={lottieRocketPath} style={{ width: 350 }} />
        </Box>
        <DialogContentText sx={{ mt: -2 }}>
          A new version of the app is available - please click refresh to update to the latest version.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton action="close" onClick={close} />
        <ActionButton action="refresh" variant="contained" onClick={() => updateServiceWorker(true)}>
          Reload
        </ActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default ReloadPrompt
