import { styled } from '@mui/material/styles'

const FlexDiv = styled('div')`
  flex-grow: 100;
  flex-shrink: 1;
`

/**
 * The `<Spacer />` is used to take up the remaining space within a flex container, this is
 * useful when you want to split items to either side of a container but you don't have exactly
 * 2 so you can't use `justify-content: space-between` without introducing lots of arbitrary
 * div wrappers.
 *
 * <Stack direction='row'>
 *   <ActionButton action='delete' />
 *   <Spacer />
 *   <ActionButton action='cancel' />
 *   <ActionButton action='submit' />
 * </Stack>
 */
export const Spacer = () => {
  return <FlexDiv />
}

export default Spacer
