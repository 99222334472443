import * as screens from 'config/routes/screens'
import React from 'react'

export interface LinkConfig {
  label: React.ReactNode
  icon?: React.ReactNode
  path: string
  exact?: boolean
  children?: LinkConfig[]
}

export const generalLinks: LinkConfig[] = [screens.dashboard, screens.courses, screens.providers]

export const archiveLinks: LinkConfig[] = [screens.archivedCourses]
