import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { Components, Theme } from '@mui/material/styles'
import type { OverridesStyleRules } from '@mui/material/styles/overrides'
import { TabPanelProps, TabPanelClassKey } from '@mui/lab/TabPanel'

declare module '@mui/material/styles' {
  interface Components<Theme = unknown> {
    MuiTabPanel: {
      defaultProps?: TabPanelProps
      styleOverrides?: Partial<OverridesStyleRules<TabPanelClassKey, 'MuiTabPanel', Theme>>
    }
  }
}

export const components: Components<Theme> = {
  MuiAccordion: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },

        borderTop: `1px solid ${theme.palette.divider}`,
      }),
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },
      }),
    },
  },
  MuiAccordionActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        ['> :not(:first-child)']: {
          marginLeft: theme.spacing(1),
        },
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },
      }),
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.neutral[500],
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(1.5, 3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2.5),
        },
      }),
      sizeMedium: ({ theme }) => ({
        padding: theme.spacing(1, 2.5),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0.75, 2),
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(0.75, 2),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0.75, 1),
        },
      }),
      textSizeLarge: ({ theme }) => ({
        padding: theme.spacing(1.5, 2),
      }),
      textSizeMedium: ({ theme }) => ({
        padding: theme.spacing(1, 2),
      }),
      textSizeSmall: ({ theme }) => ({
        padding: theme.spacing(0.75, 1.5),
      }),
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
      }),
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:first-of-type': {
          paddingTop: theme.spacing(2),
        },
        '&:last-of-type': {
          paddingBottom: theme.spacing(2),
        },
        padding: theme.spacing(1, 3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },
      }),
    },
  },
  MuiCardHeader: {
    defaultProps: {
      subheaderTypographyProps: {
        variant: 'body2',
      },
      titleTypographyProps: {
        variant: 'h6',
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
          // This looks a bit better on mobile devices but currently breaks any buttons that are absolute positioned within the card header
          // padding: theme.spacing(2, 2, 1, 2),
        },
      }),
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiChip: {
    defaultProps: { size: 'small' },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiChip-filledDefault': {
          '& .MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? theme.palette.neutral[500] : theme.palette.neutral[400],
          },
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral[800] : theme.palette.neutral[200],
        },
        '&.MuiChip-outlinedDefault': {
          '& .MuiChip-deleteIcon': {
            color: theme.palette.mode === 'dark' ? theme.palette.neutral[700] : theme.palette.neutral[300],
          },
          borderColor: theme.palette.mode === 'dark' ? theme.palette.neutral[700] : undefined,
        },
        fontWeight: 500,
        // The default variant doesn't have an outline so we add one in high contrast mode
        border: theme.palette.isHighContrast ? `1px solid ${theme.palette.divider}` : undefined,
      }),
    },
  },
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      '*': {
        boxSizing: 'border-box',
      },
      body: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
      },
      html: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        width: '100%',
      },
      ':root': {
        colorScheme: theme.palette.mode,
      },
    }),
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      }),
      paperFullScreen: ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
          marginRight: 0,
        },
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:first-of-type': {
          paddingTop: theme.spacing(1),
        },
        '&:last-of-type': {
          paddingBottom: theme.spacing(1),
        },
        padding: theme.spacing(1, 3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 2),
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 3),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        },
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: 8,
      },
      sizeSmall: {
        padding: 4,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: ({ theme }) => ({
        '&::placeholder': {
          color: theme.palette.text.secondary,
          opacity: 1,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiListItemIcon-root': {
          minWidth: 'unset',
        },
        marginRight: theme.spacing(2),
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: 1,
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        fontWeight: 500,
      },
      notchedOutline: ({ theme }) => ({
        borderColor: theme.palette.divider,
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 16,
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: 1,
      }),
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      switchBase: ({ theme }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.neutral[700] : theme.palette.neutral[500],
      }),
      track: ({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral[500] : theme.palette.neutral[400],
        opacity: 1,
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& + &': {
          marginLeft: theme.spacing(3),
        },
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
        overflow: 'visible', // Allow badges to be displayed around the tab labels without clipping
      }),
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        ['&:first-of-type']: {
          paddingLeft: theme.spacing(3),

          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
          },
        },
        ['&:last-of-type']: {
          paddingRight: theme.spacing(3),

          [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
          },
        },
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiTableCell-root': {
          color: theme.palette.mode === 'dark' ? theme.palette.neutral[300] : theme.palette.neutral[700],
          padding: theme.spacing(1.5, 2),
        },
        '& .MuiTableCell-paddingCheckbox': {
          paddingBottom: theme.spacing(0.5),
          paddingTop: theme.spacing(0.5),
        },
        '& .MuiTableCell-root': {
          fontSize: '12px',
          fontWeight: 600,
          letterSpacing: 0.5,
          lineHeight: 1,
          textTransform: 'uppercase',
        },
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral[900] : theme.palette.neutral[100],
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      margin: 'normal',
    },
  },
  MuiSlider: {
    styleOverrides: {
      valueLabel: ({ ownerState, theme }) => ({
        backgroundColor: 'transparent',
        color: theme.palette.text.secondary,
        ...(ownerState.orientation === 'vertical' && {
          left: '2rem',
        }),
      }),
    },
  },
}
