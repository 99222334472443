import { FirebaseOptions, initializeApp } from 'firebase/app'
import 'firebase/auth'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import 'firebase/firestore'
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore'
import 'firebase/storage'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAfTj0LTDb-sk7YaFSLLAtJP0P-eWbHKts',
  authDomain: 'student-tracker-43fc4.firebaseapp.com',
  databaseURL: 'https://student-tracker-43fc4.firebaseio.com',
  projectId: 'student-tracker-43fc4',
  storageBucket: 'student-tracker-43fc4.appspot.com',
  messagingSenderId: '397510232608',
  appId: '1:397510232608:web:7ccc80a869b91f3f4f8a45',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
})
export const storage = getStorage()

if (window.location.hostname === 'localhost' && import.meta.env.VITE_USE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(firestore, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
}
