/* eslint-disable no-console */
import { Logger } from './Logger'
import { ScopedLogger } from './ScopedLogger'
import { LOGGER_DEFAULT_FLAGS, LOGGER_STORAGE_FLAGS_KEY, LOGGER_STORAGE_PREFIX } from './constants'

/**
 * The different console methods that can be used via the client logger class
 */
type ConsoleMethod = 'log' | 'warn' | 'error' | 'table' | 'info'

/**
 * These types are used in the logging flag union to allow autocomplete to show the flags without restricting input
 */
type LiteralUnion<T extends U, U = string> = T | (U & { __void__?: void })

type LooseObject<TKey extends string, TValue = any> = Record<TKey, TValue> & {
  [key: string]: TValue
}

/**
 * The logging categories that can be filtered
 */
export type LoggingFlag = LiteralUnion<keyof typeof LOGGER_DEFAULT_FLAGS>

/**
 * The configurable logging flags that can be set to enable or disable logging
 */
type LoggingFlags = LooseObject<LoggingFlag, boolean>

/**
 * The [[ClientLogger]] is a wrapper around the standard console that allows log messages to be enabled or disabled
 * for different categories.
 */
export class ClientLogger implements Logger {
  constructor(public devMode: boolean = import.meta.env?.DEV) {}

  meta: Record<string, any> = {}

  private flagValues: { [flag: string]: boolean } = {}

  /** Logging categories, controls which logs are output */
  flags: LoggingFlags = LOGGER_DEFAULT_FLAGS

  /** Resets all logging flags to their default values */
  resetFlags() {
    for (const [key, value] of Object.entries(this.flags)) {
      this.flags[key] = value
    }
  }

  /**
   * Conditionally log a message based on it's category.
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The arguments that will be passed to the `console.log` function
   */
  log(category: LoggingFlag, ...args: any[]) {
    this.consoleCall('log', category, ...args)
  }

  /**
   * Conditionally log a message based on it's category.
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The arguments that will be passed to the `console.warn` function
   */
  warn(category: LoggingFlag, ...args: any[]) {
    this.consoleCall('warn', category, ...args)
  }

  /**
   * Conditionally log a message based on it's category.
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The arguments that will be passed to the `console.warn` function
   */
  info(category: LoggingFlag, ...args: any[]) {
    this.consoleCall('info', category, ...args)
  }

  /**
   * Conditionally log a message based on it's category.
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The arguments that will be passed to the `console.error` function
   */
  error(category: LoggingFlag, ...args: any[]) {
    this.consoleCall('error', category, ...args)
  }

  /**
   * Conditionally log a message based on it's category.
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The arguments that will be passed to the `console.table` function
   */
  table(category: LoggingFlag, ...args: any[]) {
    this.consoleCall('table', category, ...args)
  }

  /**
   * Creates an instance of a [[ScopedLogger]] that will automatically log under the provided category.
   *
   * @param category The message category to use for all methods
   */
  createScoped(category: LoggingFlag): ScopedLogger {
    if (category in this.scopedLoggers) {
      return this.scopedLoggers[category] as ScopedLogger
    }

    const scopedLogger = new ScopedLogger(category, this)
    this.scopedLoggers[category] = scopedLogger

    return scopedLogger
  }

  /** Keep track of created scoped logger instances for re-use */
  private scopedLoggers: Partial<LooseObject<LoggingFlag, ScopedLogger>> = {}

  /**
   * Conditionally executes a method on the console object.
   * @param method The console method to use
   * @param category The message category, this should appear in `debug.logging.flags` and must
   * be `true` for the message to be logged
   * @param args[] The args passed to the `console.<method>` function
   */
  private consoleCall(method: ConsoleMethod, category: LoggingFlag, ...args: any[]) {
    if (this.shouldLog(category, method)) {
      // eslint-disable-next-line no-console
      console[method](`%c[${category}]`, 'background: #222; color: #bada55', ...args)
    }
  }

  /** A utility function to test if a category should be logged */
  private shouldLog(flag: LoggingFlag, method?: ConsoleMethod) {
    // Always log errors
    if (method === 'error') return true

    // Check if the flag is not defined in the logging flags
    if (!(flag in this.flags)) {
      // Flag is not defined so we show a warning
      console.warn(`The category '${flag}' did not have a flag set. It has been defaulted to false.`)

      // Store the value in local storage there to preserve it across refreshes
      this.initialiseFlag(flag)
    }

    return !!(/* this.devMode &&  */ this.flags[flag])
  }

  private initialiseFlag(flag: LoggingFlag) {
    this.flagValues[flag] = false
    const key = `${LOGGER_STORAGE_PREFIX}.${flag}`

    // Add a getter and setter method for the flag
    Object.defineProperty(this.flags, flag, {
      get: () => {
        console.warn(`The category '${flag}' did not have a flag set. It should be set as a config.`)
        return this.flagValues[flag]
      },
      set: (newValue) => {
        const keys = (localStorage.getItem(LOGGER_STORAGE_FLAGS_KEY) || '')
          .split(',')
          .filter((key) => !!key?.trim().length)

        if (!(flag in keys)) {
          keys.push(`${flag}`)
          localStorage.setItem(LOGGER_STORAGE_FLAGS_KEY, keys.join(','))
        }

        // When the value is set, write it to the browser storage
        localStorage.setItem(key, newValue)
        this.flagValues[flag] = newValue
      },
    })
  }
}

/** The global default instance of the ClientLogger, this should be used in most cases */
export const globalLogger = new ClientLogger()
