import type { JSX } from 'react'

/**
 * A filtering type that checks if a string is a positional parameter in the format `:parameterName`.
 * If the input is a parameter then only the `parameterName` portion is returned, if it is not then
 * the return type is `void`. This is used in the `PathParameters` type to extract all parameters from
 * a provided URL.
 *
 * Example:
 * ```ts
 * type SampleParam = IsParameter<':departmentName'> // => 'departmentName'
 * type SampleNonParam = IsParameter<'departmentName'> // => never
 * ```
 */
type IsParameter<Parts extends string> = Parts extends `:${infer ParamName}` ? ParamName : never

/**
 * Extracts any `:parameterName` parameters from a URL path to identify the possible replacements that could
 * be performed on a path.
 *
 * Example:
 * ```ts
 * type SamplePath = '/departments/:departmentName/subdepartments/:subdepartmentID'
 * type SampleParams = PathParameters<SamplePath> // => 'departmentName' | 'subdepartmentID'
 * ```
 */
type PathParameters<Path extends string> = Path extends `${infer FirstPart}/${infer RemainingParts}`
  ? IsParameter<FirstPart> | PathParameters<RemainingParts>
  : IsParameter<Path>

/**
 * Replaces all occurrences of `:parameterName` parameters in a URL with the values provided in an object.
 * This is used to preview what a URL would look like if it had it's parameters replaced with some known values.
 *
 * Example:
 * ```ts
 * type SamplePath = '/departments/:departmentName/subdepartments/:subdepartmentID'
 * type SampleParams = { departmentName: 'Foo', subdepartmentID: 3 }
 * type SampleResult = ReplaceAllPathParams<SamplePath, SampleParams> // => '/departments/Foo/subdepartments/3'
 * ```
 */
type ReplaceAllPathParams<
  Source extends string,
  Parameters extends { [k: string]: string | number },
  Accumulator extends string = '',
> = Source extends `${Extract<keyof Parameters, string>}${infer Remainder}`
  ? Source extends `${infer ParameterName}${Remainder}`
    ? ReplaceAllPathParams<
        Remainder,
        Parameters,
        `${Accumulator}${Parameters[Extract<ParameterName, keyof Parameters>]}`
      >
    : never
  : Source extends `${infer F}:${infer R}`
    ? ReplaceAllPathParams<R, Parameters, `${Accumulator}${F}`>
    : `${Accumulator}${Source}`

export interface ScreenRouteMetadata {
  path: string
  label: string
  description?: string
  icon?: JSX.Element
}

export interface ScreenRouteDefinitionInputs<TLabel extends string, TPath extends string> {
  label: TLabel
  path: TPath
  description?: string
  icon?: JSX.Element
}

/**
 * A screen route definition that includes it's label and path and also possibly a description and icon
 * to use for navigation purposes. If the path includes any parameters (e.g. `/departments/:departmentName`)
 * then the path can't be used directly for a navigation to a screen, in that case the `getScoped` method
 * should be used to create a `ScreenRouteMetadata` object that has the parameters replaced with specific
 * values.
 *
 * > NOTE: If the path does not contain any parameters then this class is interchangeable with the
 * > `ScreenRouteMetadata` interface.
 */
export class ScreenRouteDefinition<
  TLabel extends string,
  TPath extends string,
  TParam extends string = PathParameters<TPath>,
> implements ScreenRouteMetadata
{
  label: TLabel
  path: TPath
  description?: string
  icon?: JSX.Element

  constructor(inputs: ScreenRouteDefinitionInputs<TLabel, TPath>) {
    this.label = inputs.label
    this.path = inputs.path
    this.description = inputs.description
    this.icon = inputs.icon
  }

  /**
   * Returns a `ScreenRouteMetadata` object that has the path parameter placeholders replaced with the
   * values provided in the `params` object. This is used to create a URL to a specific instance of a
   * screen that has parameters in it's path rather than the navigation route definition.
   */
  getScoped<const TScopeParams extends Record<TParam, string | number>>(params: TScopeParams) {
    const substitutedPath = this.path.replace(/:\w+/g, (match) => {
      const paramName = match.substring(1) as keyof TParam
      return params[paramName].toString()
    }) as ReplaceAllPathParams<TPath, TScopeParams>

    return {
      label: this.label,
      path: substitutedPath,
      description: this.description,
      icon: this.icon,
    } as const satisfies ScreenRouteMetadata
  }
}

export type ScreenRouteParameters<T extends ScreenRouteDefinition<any, any>> = PathParameters<T['path']>
