import { lazy } from 'react'
import * as screensMetadata from './screens'
import { RouteItem } from './types'

export const routes: RouteItem[] = [
  {
    ...screensMetadata.dashboard,
    exact: true,
    screen: lazy(() => import('screens/Dashboard')),
  },
  {
    ...screensMetadata.courses,
    exact: true,
    screen: lazy(() => import('screens/CourseList')),
  },
  {
    ...screensMetadata.courseDetails,
    exact: true,
    screen: lazy(() => import('screens/CourseDetails')),
  },
  {
    ...screensMetadata.archivedCourses,
    exact: true,
    screen: lazy(() => import('screens/CourseList')),
  },
  {
    ...screensMetadata.archivedCourseDetails,
    exact: true,
    screen: lazy(() => import('screens/CourseDetails')),
  },
  { ...screensMetadata.providers, exact: true, screen: lazy(() => import('screens/ProvidersList')) },
  {
    ...screensMetadata.providerDetails,
    exact: true,
    screen: lazy(() => import('screens/ProviderDetails')),
  },
  {
    path: '/',
    redirect: screensMetadata.courses.path,
  },
]
