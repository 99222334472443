import PinIcon from '@mui/icons-material/Pin'
import SettingsIcon from '@mui/icons-material/Settings'
import UpdateIcon from '@mui/icons-material/Update'
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid2,
  IconButton,
  Radio,
  Skeleton,
  Stack,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material'
import { useGlobalStore } from 'app/globalStore'
import ActionButton from 'components/ActionButton'
import { useIsSmallScreen } from 'hooks/useIsSmallScreen'
import { useState } from 'react'
import { useAuth } from 'reactfire'
import { darkTheme, highContrastTheme, lightTheme } from 'utils/theme'
import { AppTheme } from 'utils/theme/palette'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { ChangePinDialog } from './ChangePinDialog'

interface ThemeOption {
  name: string
  themeKey: AppTheme
  theme: Theme
}

const themes: ThemeOption[] = [
  { name: 'Light', themeKey: 'light', theme: lightTheme },
  { name: 'Dark', themeKey: 'dark', theme: darkTheme },
  { name: 'High Contrast', themeKey: 'highContrast', theme: highContrastTheme },
]

export const SettingsButton = () => {
  const [open, setOpen] = useState(false)
  const setTheme = useGlobalStore((store) => store.setTheme)
  const theme = useGlobalStore((store) => store.theme)
  const auth = useAuth()
  const isSmallScreen = useIsSmallScreen()

  const [pinDialogOpen, setPinDialogOpen] = useState(false)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW()

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <Badge color="warning" variant="dot" invisible={!needRefresh}>
          <SettingsIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        variant="temporary"
        SlideProps={{ unmountOnExit: true }}
        PaperProps={{ sx: { width: isSmallScreen ? 'min(85vw, max(75vw, 500px))' : 500 } }}
      >
        <Toolbar>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="h6">Settings</Typography>
            <Button onClick={() => auth.signOut()}>Logout</Button>
          </Stack>
        </Toolbar>

        <Divider />

        <CardContent sx={{ mt: 2 }}>
          <Stack spacing={2}>
            {needRefresh && (
              <Card>
                <CardActionArea onClick={() => updateServiceWorker(true)}>
                  <CardContent>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <UpdateIcon color="warning" sx={{ fontSize: 30 }} />
                      <div>
                        <Typography variant="h6">Update Available</Typography>
                        <Typography variant="caption" color="text.secondary">
                          Click to update to the latest version.
                        </Typography>
                      </div>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}

            <div>
              <Typography variant="h6" gutterBottom>
                Profile
              </Typography>
              <Grid2 container>
                <Grid2
                  size={{
                    xs: 6,
                    sm: 4,
                  }}
                >
                  <ActionButton
                    action="custom"
                    icon={<PinIcon />}
                    sx={{ width: '100%' }}
                    onClick={() => setPinDialogOpen(true)}
                  >
                    Change PIN
                  </ActionButton>
                </Grid2>
              </Grid2>

              <ChangePinDialog open={pinDialogOpen} onClose={() => setPinDialogOpen(false)} />
            </div>

            <div>
              <Typography variant="h6" gutterBottom>
                Theme
              </Typography>
              <Grid2 container spacing={2}>
                {themes.map((option) => (
                  <Grid2
                    key={option.themeKey}
                    size={{
                      xs: 12,
                      sm: 6,
                    }}
                  >
                    <ThemeProvider theme={option.theme}>
                      <Card>
                        <CardActionArea onClick={() => setTheme(option.themeKey)}>
                          <CardHeader
                            sx={{ pt: 1, pb: 0 }}
                            title={
                              <>
                                <Radio
                                  sx={{ ['&:hover']: { background: 'none' } }}
                                  checked={option.themeKey === theme}
                                />
                                {option.name}
                              </>
                            }
                          />
                          <CardContent>
                            <Stack spacing={1}>
                              <Stack direction="row" spacing={1}>
                                <Skeleton animation={false}>
                                  <Avatar />
                                </Skeleton>
                                <Skeleton animation={false} variant="text" width={100} />
                              </Stack>
                              <Skeleton animation={false} variant="text" />
                              <Skeleton animation={false} variant="text" />
                              <Skeleton animation={false} variant="text" />
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </ThemeProvider>
                  </Grid2>
                ))}
              </Grid2>
            </div>
          </Stack>
        </CardContent>
      </Drawer>
    </>
  )
}

export default SettingsButton
