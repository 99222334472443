export const LOGGER_STORAGE_PREFIX = 'debug.logging'

export const LOGGER_STORAGE_FLAGS_KEY = `${LOGGER_STORAGE_PREFIX}.flags`

export const LOGGER_DEFAULT_FLAGS = {
  AUTH: true,
  GENERAL: true,
  UI: true,
  API: true,
  SW: true,
} as const
