import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { breakpoints } from './breakpoints'
import { components } from './components'
import { darkPalette, highContrastDarkPalette, lightPalette } from './palette'
import { darkShadows, highContrastShadows, lightShadows } from './shadows'
import { shape } from './shape'
import { typography } from './typography'

type ThemeOptions = Parameters<typeof createTheme>[0]

const common: ThemeOptions = { /* cssVariables: true, */ breakpoints, components, shape, typography }

export const lightThemeOptions: ThemeOptions = {
  ...common,
  palette: lightPalette,
  shadows: lightShadows,
}

export const lightTheme = responsiveFontSizes(createTheme(lightThemeOptions))

export const darkThemeOptions: ThemeOptions = {
  ...common,
  palette: darkPalette,
  shadows: darkShadows,
}

export const darkTheme = responsiveFontSizes(createTheme(darkThemeOptions))

export const highContrastThemeOptions: ThemeOptions = {
  ...common,
  palette: highContrastDarkPalette,
  shadows: highContrastShadows,
}

export const highContrastTheme = responsiveFontSizes(createTheme(highContrastThemeOptions))
