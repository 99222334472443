import type { BreakpointsOptions } from '@mui/material/styles'

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1000,
    lg: 1200,
    xl: 1920,
  },
}
