import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { LinkConfig } from '../../config/routes/sidebar'
import { styled } from '@mui/material/styles'

const StyledListItemButton = styled(ListItemButton)`
  margin: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
` as typeof ListItemButton

export interface SideBarListItemProps {
  link: LinkConfig
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

function SideBarListItem(props: SideBarListItemProps) {
  const { link, onClick } = props
  const location = useLocation()

  const selected = link.exact ? location.pathname === link.path : location.pathname.startsWith(link.path)

  return (
    <StyledListItemButton onClick={onClick} selected={selected} component={RouterLink} to={link.path} dense>
      <ListItemIcon>{link.icon}</ListItemIcon>
      <ListItemText primary={link.label} />
    </StyledListItemButton>
  )
}

export default SideBarListItem
