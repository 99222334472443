import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import BusinessIcon from '@mui/icons-material/Business'
import ArchiveIcon from '@mui/icons-material/Archive'
import { ScreenRouteDefinition } from 'utils/routes'

export const dashboard = new ScreenRouteDefinition({
  label: 'Dashboard',
  path: '/dashboard',
  icon: <DashboardIcon />,
})

export const courses = new ScreenRouteDefinition({
  label: 'Courses',
  path: '/courses',
  icon: <PeopleIcon />,
})

export const courseDetails = new ScreenRouteDefinition({
  label: 'Course Details',
  path: `${courses.path}/:courseID`,
})

export const archivedCourses = new ScreenRouteDefinition({
  label: 'Course Archive',
  path: '/archive/courses',
  icon: <ArchiveIcon />,
})

export const archivedCourseDetails = new ScreenRouteDefinition({
  label: 'Courses',
  path: `${archivedCourses.path}/:courseID`,
})

export const providers = new ScreenRouteDefinition({
  label: 'Providers',
  path: '/providers',
  icon: <BusinessIcon />,
})

export const providerDetails = new ScreenRouteDefinition({
  label: 'Providers Details',
  path: `${providers.path}/:providerID`,
})

export const login = new ScreenRouteDefinition({
  label: 'Login',
  path: '/login',
})
