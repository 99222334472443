import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { useGlobalStore } from 'app/globalStore'
import { routes } from 'config/routes'
import ReloadPrompt from 'containers/ReloadPrompt'
import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Navigate, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom'
import { AuthProvider, FirestoreProvider, useAuth } from 'reactfire'
import { useUserProfile } from 'services/profile'
import { auth, firestore } from 'utils/firebase'
import { sentryCreateBrowserRouter } from 'utils/sentry'
import { darkTheme, highContrastTheme, lightTheme } from 'utils/theme'
import LoadingSplash from './components/LoadingSplash'
import AppLayout from './containers/AppLayout'

const Registration = lazy(() => import('screens/Registration'))
const Login = lazy(() => import('screens/Login'))

const VisibleRoot = () => {
  const profile = useUserProfile()

  if (profile.data?.registrationMode) {
    return <Registration courseID={profile.data.registrationMode} />
  }

  return (
    <AppLayout>
      <Suspense fallback={<LoadingSplash title="Loading..." message="Please wait while we get the screen ready" />}>
        <Outlet />
      </Suspense>
      <ScrollRestoration />
    </AppLayout>
  )
}

const LoginWrapperRoot = () => {
  const auth = useAuth()

  const [user, setUser] = useState(auth.currentUser)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        Sentry.setUser({
          // eslint-disable-next-line camelcase
          ip_address: '{{auto}}',
          email: user.email ?? '',
          username: user.displayName ?? '',
          id: user.uid,
        })
        Sentry.addBreadcrumb({ message: 'User signed in', category: 'auth', level: 'info' })
      } else {
        Sentry.setUser(null)
        Sentry.addBreadcrumb({ message: 'User signed out', category: 'auth', level: 'info' })
      }
      setUser(user)
    })

    return () => unsubscribe()
  }, [auth])

  if (!user) {
    return <Login />
  }

  return <VisibleRoot />
}

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <LoginWrapperRoot />,
    children: routes.map((route) => ({
      path: route.path,
      element: 'redirect' in route ? <Navigate to={route.redirect} replace /> : <route.screen />,
    })),
  },
])

function App() {
  const themeSetting = useGlobalStore((store) => store.theme)

  const activeTheme = useMemo(() => {
    switch (themeSetting) {
      case 'light':
        return lightTheme
      case 'dark':
        return darkTheme
      case 'highContrast':
        return highContrastTheme
      default:
        return darkTheme
    }
  }, [themeSetting])

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <ThemeProvider theme={activeTheme}>
          <ReloadPrompt />
          <CssBaseline />
          <Toaster
            toastOptions={{
              style: {
                borderRadius: activeTheme.shape.borderRadius,
                border: `1px solid ${activeTheme.palette.divider}`,
                color: activeTheme.palette.text.primary,
                background:
                  activeTheme.palette.mode === 'dark'
                    ? activeTheme.palette.background.paper
                    : activeTheme.palette.background.paper,
                maxWidth: '500px',
              },
            }}
          />

          <Suspense fallback={<LoadingSplash message="Please wait while we get the screen ready" />}>
            <RouterProvider router={router} />
          </Suspense>
        </ThemeProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}

export default App
