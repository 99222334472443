import { Theme } from '@mui/material/styles'

type ThemeContext = { theme: Theme }

export type StyleFunction<TProps extends Record<PropertyKey, any> = Record<PropertyKey, any>> = (
  props: TProps & ThemeContext,
) => string

export function spacing(): StyleFunction
export function spacing(units: number): StyleFunction
export function spacing(topBottom: number, rightLeft: number): StyleFunction
export function spacing(top: number, rightLeft: number, bottom: number): StyleFunction
export function spacing(top: number, right: number, bottom: number, left: number): StyleFunction
export function spacing(...args: number[]): StyleFunction {
  return ({ theme }: ThemeContext) => (theme.spacing as any)(...args)
}
