import type { AppTheme } from 'utils/theme/palette'
import { create } from 'zustand'

export interface GlobalStore {
  theme: AppTheme
  setTheme: (theme: AppTheme) => void
}

export const useGlobalStore = create<GlobalStore>()((set) => ({
  theme: (localStorage.getItem('attendix.appSettings.theme') as AppTheme) ?? 'dark',
  setTheme: (theme) => {
    set(() => {
      localStorage.setItem('attendix.appSettings.theme', theme)
      return { theme }
    })
  },
}))
