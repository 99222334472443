import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { QueryClientProvider } from '@tanstack/react-query'
import { enAU } from 'date-fns/locale/en-AU'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { FirebaseAppProvider } from 'reactfire'
import { app } from 'utils/firebase'
import { queryClient } from 'utils/reactQuery'
import { configureSentry } from 'utils/sentry'
import App from './App'

configureSentry()

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FirebaseAppProvider firebaseApp={app}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
          <App />
        </LocalizationProvider>
      </FirebaseAppProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
