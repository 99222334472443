import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React, { useMemo, type JSX } from 'react'
import { Action, CustomAction, StandardAction, defaultActionLabels, standardActionIcons } from './config'

const defaultActionVariants: Record<Action, LoadingButtonProps['variant']> = {
  clear: 'text',
  close: 'outlined',
  create: 'contained',
  delete: 'contained',
  save: 'contained',
  cancel: 'outlined',
  edit: 'outlined',
  next: 'outlined',
  previous: 'outlined',
  confirm: 'contained',
  download: 'contained',
  upload: 'contained',
  refresh: 'outlined',
  custom: 'outlined',
}

const defaultActionColors: Record<Action, LoadingButtonProps['color']> = {
  clear: 'primary',
  close: 'inherit',
  create: 'primary',
  delete: 'error',
  save: 'primary',
  cancel: 'inherit',
  edit: 'primary',
  next: 'primary',
  previous: 'primary',
  confirm: 'primary',
  download: 'primary',
  upload: 'primary',
  refresh: 'primary',
  custom: 'primary',
}

type IconPosition = 'start' | 'end'

const useActionButtonIconProps = (
  props: Pick<ActionButtonProps, 'action' | 'icon' | 'iconPosition'>,
): Pick<LoadingButtonProps, 'startIcon' | 'endIcon'> => {
  const iconToUse = useMemo(() => {
    if (props.action === 'custom' || props.icon) return props.icon
    return standardActionIcons[props.action]
  }, [props.action, props.icon])

  const buttonIconProps = useMemo(() => {
    const iconProps: Pick<LoadingButtonProps, 'startIcon' | 'endIcon'> = {}

    // If a custom position is provided then use that
    if (props.iconPosition) {
      iconProps[`${props.iconPosition}Icon`] = iconToUse

      // No custom position provided, only the 'next' button has it on the right
    } else if (props.action === 'next') {
      iconProps.endIcon = iconToUse

      // Otherwise everything else is on the left
    } else {
      iconProps.startIcon = iconToUse
    }

    return iconProps
  }, [iconToUse, props.iconPosition, props.action])

  return buttonIconProps
}

const iconButtonPaddingLargeScreen = {
  small: 0.75,
  medium: 1,
  large: 1.5,
}

const iconButtonPaddingSmallScreen = {
  small: 0.75,
  medium: 0.75,
  large: 1,
}

const shouldForwardProp = (prop: string) => prop !== 'action' && prop !== 'iconButton'

const StyledLoadingButton = styled(LoadingButton as (props: Omit<LoadingButtonProps, 'action'>) => JSX.Element, {
  shouldForwardProp,
})<{
  action: Action
  iconButton?: boolean
}>`
  // If an icon button then leave the max width as auto, if a normal version using next or previous set the min width to match
  min-width: ${({ action, iconButton }) =>
    iconButton ? 'auto' : action === 'next' || action === 'previous' ? '130px' : undefined};

  // If rendering as an icon only then scale the icon up to be the same size as a normal icon
  font-size: ${({ iconButton }) => (iconButton ? '20px' : undefined)};

  // Match the padding from the global component theme for buttons but make it square if it's an icon button version
  padding: ${({ iconButton, theme, size }) =>
    iconButton ? theme.spacing(iconButtonPaddingLargeScreen[size ?? 'medium']) : undefined};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ iconButton, theme, size }) =>
      iconButton ? theme.spacing(iconButtonPaddingSmallScreen[size ?? 'medium']) : undefined};
  }
`

export type ActionButtonProps<TComponent extends React.ElementType = React.ElementType<HTMLAnchorElement>> = Omit<
  LoadingButtonProps<TComponent>,
  'onClick' | 'action' | 'color' | 'variant' | 'endIcon' | 'startIcon'
> & {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => any
  /**
   * Override the default icon position (start for most, end in some cases like a next button)
   */
  iconPosition?: IconPosition
  /** Override the default icon with a custom one */
  icon?: React.ReactNode
  /** Replace button implementation with an IconButton (this will hide the label) */
  iconButton?: boolean
  /** Override the default variant with a custom one */
  variant?: LoadingButtonProps['variant']
  /** Override the default color for the action with a custom one */
  color?: LoadingButtonProps['color']
  loading?: boolean
  tooltip?: string
} & (
    | {
        /** The type of action this button represents */
        action: StandardAction
        /** Override the default label with a custom one */
        children?: string
      }
    | {
        action: CustomAction
        children: string
      }
  )

/**
 * The `<ActionButton />` is a standardised wrapper around the MUI `<LoadingButton />`
 * component. There are standard actions that can be passed with the `action` prop, these
 * will determine the colour, label and icon/icon placement for the button. Each of these
 * defaults can be overwritten if required but the defaults should be used as a first preference
 * to promote consistency throughout the applications.
 *
 * ```tsx
 * <Stack direction="row" spacing={1}>
 *   <ActionButton action='delete' />
 *   <Spacer />
 *   <ActionButton action='cancel' />
 *   <ActionButton action='save' />
 * </Stack>
 * ```
 */
export const ActionButton = <TComponent extends React.ElementType = React.ElementType>(
  props: ActionButtonProps<TComponent>,
) => {
  const { action, variant, color, icon, iconButton, iconPosition, loading, tooltip, ...otherProps } = props

  const buttonIconProps = useActionButtonIconProps({ icon, iconPosition, action })

  const button = (
    <StyledLoadingButton
      action={action}
      iconButton={iconButton}
      {...(iconButton ? {} : buttonIconProps)}
      variant={props.variant ?? defaultActionVariants[props.action]}
      color={props.color ?? defaultActionColors[props.action]}
      loading={loading}
      {...otherProps}
    >
      {iconButton
        ? (buttonIconProps.startIcon ?? buttonIconProps.endIcon)
        : (props.children ?? defaultActionLabels[props.action])}
    </StyledLoadingButton>
  )

  if (tooltip)
    return (
      <Tooltip title={tooltip}>
        {otherProps.disabled ? (
          /**
           * Need to wrap the button in a span if it's disabled - a disabled button wont fire mouse events so a tooltip wont show up
           * @see https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-mui-button-within-a-buttongroup
           */
          <span>{button}</span>
        ) : (
          button
        )}
      </Tooltip>
    )

  return button
}

export default ActionButton
