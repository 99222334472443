import CreateIcon from '@mui/icons-material/Add'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveIcon from '@mui/icons-material/Save'
import UploadIcon from '@mui/icons-material/Upload'

export type StandardAction =
  | 'clear'
  | 'close'
  | 'create'
  | 'delete'
  | 'save'
  | 'cancel'
  | 'edit'
  | 'next'
  | 'previous'
  | 'confirm'
  | 'download'
  | 'upload'
  | 'refresh'

export type CustomAction = 'custom'
export type Action = StandardAction | CustomAction

export const standardActionIcons: Record<Action, React.ReactNode | undefined> = {
  clear: <ClearIcon />,
  close: <CancelIcon />,
  create: <CreateIcon />,
  delete: <DeleteIcon />,
  save: <SaveIcon />,
  cancel: <CancelIcon />,
  edit: <EditIcon />,
  next: <ArrowRightIcon />,
  previous: <ArrowLeftIcon />,
  confirm: <CheckIcon />,
  download: <DownloadIcon />,
  upload: <UploadIcon />,
  refresh: <RefreshIcon />,
  custom: undefined,
}

export const defaultActionLabels: Record<Action, string> = {
  clear: 'Clear',
  close: 'Close',
  create: 'Create',
  delete: 'Delete',
  save: 'Save',
  cancel: 'Cancel',
  edit: 'Edit',
  next: 'Next',
  previous: 'Previous',
  confirm: 'Confirm',
  download: 'Download',
  upload: 'Upload',
  refresh: 'Refresh',
  custom: '',
}
