import MenuIcon from '@mui/icons-material/Menu'
import { Stack, Toolbar, Typography } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Spacer from 'components/Spacer'
import { APP_TITLE, DRAWER_WIDTH } from 'config/constants'
import SettingsButton from './SettingsButton'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

function shouldForwardProp(prop: string) {
  return prop !== 'open'
}

const AppBar = styled(MuiAppBar, { shouldForwardProp })<AppBarProps>`
  transition: ${({ theme, open }) =>
    open
      ? theme.transitions.create(['width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% - ${({ open }) => (open ? DRAWER_WIDTH : 0)}px);
    margin-left: ${({ open }) => (open ? DRAWER_WIDTH : 0)}px;
  }
`

interface HeaderProps {
  drawerOpen: boolean
  setDrawerOpen: (open: boolean) => void
}

const Header = (props: HeaderProps) => {
  const { drawerOpen, setDrawerOpen } = props

  return (
    <AppBar
      position="fixed"
      open={drawerOpen}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, ...(drawerOpen && { display: { md: 'none', sm: 'block' } }) }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>

        <Stack direction="row" spacing={4} alignItems="center" width="100%">
          <Typography variant="h5" component="div">
            {APP_TITLE}
          </Typography>

          <Spacer />

          <SettingsButton />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Header
