import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Typography } from '@mui/material'
import ActionButton from 'components/ActionButton'
import PinInput, { PinValue } from 'components/PinInput'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useChangePinMutation } from 'services/profile'
import { standardToastMessages } from 'utils/toast/toasts'

export interface ChangePinDialogProps {
  open: boolean
  onClose: () => void
}

const toastMessages = standardToastMessages('pin')

export const ChangePinDialog = ({ open, onClose }: ChangePinDialogProps) => {
  const [pin, setPin] = useState<PinValue>(['', '', '', ''])
  const [confirmPin, setConfirmPin] = useState<PinValue>(['', '', '', ''])

  useEffect(() => {
    if (open) {
      setPin(['', '', '', ''])
      setConfirmPin(['', '', '', ''])
    }
  }, [open])

  const [pinError, setPinError] = useState<string | null>(null)

  const handleChangePin = (pin: PinValue) => {
    setPin(pin)
    setPinError(null)
  }

  const handleChangeConfirmPin = (pin: PinValue) => {
    setConfirmPin(pin)
    setPinError(null)
  }

  const changePinMutation = useChangePinMutation()

  const handleSave = async () => {
    if (pin.some((digit) => !digit) || confirmPin.some((digit) => !digit)) {
      setPinError('Please enter a PIN')
      return
    }

    const parsedPin = parseInt(pin.join(''))
    const parsedConfirmPin = parseInt(confirmPin.join(''))

    if (parsedPin !== parsedConfirmPin) {
      setPinError('The PINs do not match')
      return
    }

    await toast.promise(changePinMutation.mutateAsync(parsedPin), toastMessages.update)
    onClose()
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Change PIN</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom color={pinError ? 'error' : undefined}>
          Enter your new PIN
        </Typography>
        <PinInput
          pin={pin}
          onChange={handleChangePin}
          focusFirst={open}
          disabled={changePinMutation.isPending}
          error={!!pinError}
        />

        <Typography variant="body2" gutterBottom sx={{ mt: 2 }} color={pinError ? 'error' : undefined}>
          Confirm your new PIN
        </Typography>
        <PinInput
          pin={confirmPin}
          onChange={handleChangeConfirmPin}
          disabled={changePinMutation.isPending}
          error={!!pinError}
        />

        <FormHelperText error>{pinError}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <ActionButton action="cancel" onClick={onClose} disabled={changePinMutation.isPending} />
        <ActionButton action="save" onClick={handleSave} loading={changePinMutation.isPending} />
      </DialogActions>
    </Dialog>
  )
}
