import { Box, TextField } from '@mui/material'
import { useEffect, useRef } from 'react'

export type PinValue = [string, string, string, string]

export interface PinInputProps {
  focusFirst?: boolean
  pin: PinValue
  onChange: (value: PinValue) => void
  disabled?: boolean
  error?: boolean
}

const PinInput = ({ focusFirst, onChange, pin, disabled, error }: PinInputProps) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (/\D/.test(value)) return // Only allow numeric input

    const newPin = [...pin] as PinValue
    newPin[index] = value
    onChange(newPin)

    // Move to next input if a digit is entered
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key

    if (key === 'Backspace' && !pin[index] && index > 0) {
      // Move to previous input if current one is empty and backspace is pressed
      inputRefs.current[index - 1]?.focus()
    } else if (key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus()
    } else if (key === 'ArrowRight' && index < 3) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  // Reset the pin and focus the first input when the dialog is opened
  useEffect(() => {
    if (focusFirst) {
      setTimeout(() => inputRefs.current[0]?.focus(), 100)
    }
  }, [focusFirst])

  return (
    <Box display="flex" justifyContent="center" gap={2}>
      {pin.map((digit, index) => (
        <TextField
          tabIndex={index + 1}
          key={index}
          value={digit}
          onChange={handleChange(index)}
          onKeyDown={handleKeyDown(index)}
          type="password"
          disabled={disabled}
          error={error}
          margin="none"
          inputRef={(ref) => (inputRefs.current[index] = ref)}
          slotProps={{
            htmlInput: {
              maxLength: 1,
              inputMode: 'numeric',
              pattern: '[0-9]',
              style: { textAlign: 'center', fontSize: '2rem', width: '3rem' },
            },
          }}
        />
      ))}
    </Box>
  )
}

export default PinInput
