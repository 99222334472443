const capitalize = (value: string): string => {
  const start = value.slice(0, 1).toUpperCase()
  const rest = value.slice(1).toLowerCase()
  return `${start}${rest}`
}

function standardVerbToastMessages<
  TEntity extends string,
  TVerb extends string,
  TPastTenseVerb extends string,
  TCapitalizedEntity extends string = Capitalize<TEntity>,
>(entity: TEntity, capitalizedEntity: TCapitalizedEntity, verb: TVerb, pastTenseVerb: TPastTenseVerb) {
  const capitalizedVerb = capitalize(verb) as Capitalize<TVerb>
  return {
    loading: `${capitalizedVerb} ${entity}...` as const,
    success: `${capitalizedEntity} ${pastTenseVerb}!` as const,
    error: (error: any) => {
      if (error instanceof Error || (typeof error === 'object' && 'message' in error)) {
        return error.message
      } else if (typeof error === 'string') {
        return error
      } else {
        return `Error ${verb} the ${entity}, please try again`
      }
    },
  }
}

/**
 * Creates a set of toast messages in a standard format for use with react-hot-toast in a promise setting.
 *
 * @param entity The lower case name of the entity that is being targeted
 * @param capitalizedEntity The capitalized name of the entity - this is only required if capitalizing the first letter isn't the correct way to case it
 * @returns An object that can be passed directly to toast.promise(promise, <HERE>) that will provide all messages
 *
 * ```tsx
 * const userMessages = standardToastMessages('user')
 *
 * await toast.promise(createUser(), userMessages.create)
 * // Will show 'Creating user...' while loading then
 * // - on success: 'User created!'
 * // - on error: The error message if one is provided or 'Error creating the user, please try again'
 * ```
 */
export function standardToastMessages<TEntity extends string, TCapitalizedEntity extends string = Capitalize<TEntity>>(
  entity: TEntity,
  capitalizedEntity?: TCapitalizedEntity,
) {
  const capitalizedEntityToUse = capitalizedEntity || (capitalize(entity) as TCapitalizedEntity)
  return {
    create: standardVerbToastMessages(entity, capitalizedEntityToUse, 'creating', 'created'),
    update: standardVerbToastMessages(entity, capitalizedEntityToUse, 'updating', 'updated'),
    delete: standardVerbToastMessages(entity, capitalizedEntityToUse, 'deleting', 'deleted'),
    createCustom: <TVerb extends string, TPastTenseVerb extends string>(verb: TVerb, pastTenseVerb: TPastTenseVerb) =>
      standardVerbToastMessages(entity, capitalizedEntityToUse, verb, pastTenseVerb),
  }
}
