import { useMutation } from '@tanstack/react-query'
import { User } from 'firebase/auth'
import { doc, DocumentReference, updateDoc } from 'firebase/firestore'
import toast from 'react-hot-toast'
import { useFirestoreDocData, useUser } from 'reactfire'
import { firestore } from 'utils/firebase'

export interface UserProfile {
  patch: string
  pin: number
  registrationMode: string
  fcmToken: string
  fcmTokens: Record<string, Date>
}

export function useUserProfile() {
  const authUser = useUser()
  return useFirestoreDocData(doc(firestore, `users/${authUser.data?.uid}`) as DocumentReference<UserProfile>, {
    idField: 'id',
  })
}

const changePin = async ({ account, pin }: { account: User | null; pin: number }) => {
  if (!account) return toast.error('You must be logged in to change your PIN')
  await updateDoc(doc(firestore, `users/${account.uid}`), { pin })
}

export function useChangePinMutation() {
  const authUser = useUser()
  return useMutation({ mutationFn: (pin: number) => changePin({ account: authUser.data, pin }) })
}
