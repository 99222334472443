import { Logger } from './Logger'
import { ClientLogger, LoggingFlag } from './ClientLogger'

export class ScopedLogger implements Logger {
  constructor(
    public category: LoggingFlag,
    private logger: ClientLogger,
  ) {}

  log(...args: any[]) {
    this.logger.log(this.category, ...args)
  }

  warn(...args: any[]) {
    this.logger.warn(this.category, ...args)
  }

  error(...args: any[]) {
    this.logger.error(this.category, ...args)
  }

  info(...args: any[]) {
    this.logger.info(this.category, ...args)
  }

  table(...args: any[]) {
    this.logger.table(this.category, ...args)
  }
}
