import { Box, useTheme } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import Content from './Content'
import Header from './Header'
import SideBar from './SideBar'

const AppLayout = (props: React.PropsWithChildren) => {
  const { children } = props

  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState(() => window.innerWidth >= theme.breakpoints.values.md)

  const toaster = useMemo(() => {
    const { shape, palette } = theme
    return (
      <Toaster
        toastOptions={{
          style: {
            borderRadius: shape.borderRadius,
            border: `1px solid ${palette.divider}`,
            color: palette.text.primary,
            background: palette.mode === 'dark' ? palette.background.paper : palette.background.paper,
            maxWidth: '500px',
          },
        }}
      />
    )
  }, [theme])

  return (
    <Box display="flex">
      <SideBar open={drawerOpen} setOpen={setDrawerOpen} />
      <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />

      <Content drawerOpen={drawerOpen}>{children}</Content>
      {toaster}
    </Box>
  )
}
export default AppLayout
